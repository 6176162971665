<template>
  <div class="container">
    <div class="head">
      <div class="start">
        <div class="breadcrumb">
          <p>{{ $route.meta.header }}</p>
          <span class="slash">/</span>
        </div>
      </div>
      <slot name="buttons">
        <div class="buttons">
          <button class="loose thin" @click="submit">submit</button>
          <button class="loose transparent thin" @click="$logout">
            log out
          </button>
        </div>
      </slot>
    </div>
    <div class="body">
      <slot />
    </div>
    <div class="foot">
      <slot name="foot" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  .head {
    height: 42px;
    display: flex;
    padding: 8px;
    flex-shrink: 0;

    > * {
      margin-top: auto;
      margin-bottom: auto;
    }

    .start {
      flex-grow: 1;
      text-align: left;
    }
  }
  .body {
    flex-grow: 1;
    padding: 8px;
  }
  .foot {
    background-color: white;
    margin: 0 -20px;
  }
}
</style>
