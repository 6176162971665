<template>
  <div class="card">
    <!-- FOR FEED BUTTON  -->
    <slot />
    <div class="match-title">
      <p>{{ title }}</p>
    </div>
    <div class="versus-wrapper">
      <div class="versus">
        <div class="team-name left">
          <span>{{ team1 }}</span>
        </div>
        <div class="club-logo">
          <img
            :src="`${s3Url}/logos/resize/${club1._id}.png`"
            @error="displayPlaceholder"
            :alt="team1"
          />
        </div>
        <div class="time-wrap">
          <span class="time" v-if="time">{{ time }}</span>
          <div class="scores d-flex" v-else>
            <div>
              <component
                :is="parseInt(score1) > parseInt(score2) ? 'b' : 'span'"
                >{{ score1 }}</component
              >
            </div>
            <div class="caps grey">full time</div>
            <div>
              <component
                :is="parseInt(score2) > parseInt(score1) ? 'b' : 'span'"
                >{{ score2 }}</component
              >
            </div>
          </div>
        </div>
        <div class="club-logo">
          <img
            :src="`${s3Url}/logos/resize/${club2._id}.png`"
            @error="displayPlaceholder"
            :alt="team2"
          />
        </div>
        <div class="team-name right">
          <span>{{ team2 }}</span>
        </div>
      </div>
    </div>
    <span class="match-title">{{ place || 'NA' }}</span>
  </div>
</template>
<script>
export default {
  name: 'Match',
  props: [
    'title',
    'team1',
    'club1',
    'team2',
    'club2',
    'time',
    'place',
    'score1',
    'score2',
  ],
  computed: {
    s3Url() {
      return this.$store.state.root.s3Url;
    },
  },
  methods: {
    displayPlaceholder(event) {
      event.target.src = require('../assets/img/logo/shield_black.svg');
    },
  },
};
</script>
<style lang="scss" scoped>
.grey {
  color: grey;
}
.left {
  text-align: right;
}
.right {
  text-align: left;
}
.versus-wrapper {
  display: flex;
}
.time-wrap {
  min-width: 100px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  .scores {
    flex-grow: 1;
    justify-content: space-between;
    max-width: 300px;
    font-weight: normal;
  }
}
.versus {
  /*justify-content: space-between;*/
  flex-grow: 1;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 80px;
}
.club-logo {
  width: 50px;
  margin: 0 7px;
  img {
    max-width: 100%;
    height: auto;
  }
}

.team-name {
  width: 200px;
  max-width: calc(100% - 100px);
  @media (max-width: $tablet) {
    font-size: 0.7em;
  }
}

.versus {
  font-weight: 500;
  display: flex;

  * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.block {
  min-width: 50px;
}
.match-title {
  font-size: 0.7em;
  color: grey;
}
</style>
