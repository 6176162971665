<template>
  <component :is="Default">
    <template slot="buttons">
      <div class="buttons">
        <button class="loose thin" @click="$router.push('/landing')">
          Dashboard
        </button>
        <button class="loose transparent thin" @click="$logout">log out</button>
      </div>
    </template>
    <div class="d-flex filters">
      <v-select
        placeholder="Select Competition"
        :options="competitions"
        label="name"
        v-model="selectedCompetition"
        class="w-45"
        :clearable="false"
      />
      <v-select
        placeholder="Select Round"
        :options="rounds"
        v-model="selectedRound"
        class="w-45"
        :clearable="false"
      />
    </div>

    <div class="competitions">
      <div
        v-for="(f, index) in filteredMatches"
        :key="index"
        @click="select(f)"
        class="mt-20 clickable"
      >
        <Match
          :place="f.venue ? f.venue.name : ''"
          :team1="f.homeTeam ? f.homeTeam.name : ''"
          :team2="f.awayTeam ? f.awayTeam.name : ''"
          :club1="f.homeTeam ? f.homeTeam.orgtree.club : {}"
          :club2="f.awayTeam ? f.awayTeam.orgtree.club : {}"
          :score1="f.scores ? f.scores.homeTeam : undefined"
          :score2="f.scores ? f.scores.awayTeam : undefined"
          :time="matchTime(f.dateTime)"
          :title="`Round ${f.round.number} ${matchDate(f.dateTime)}`"
        />
      </div>
    </div>
  </component>
</template>

<script>
import moment from "moment";
import range from "lodash/range";
import Default from "@/layouts/default";
import { randomChoice } from "@/utils/_dev/random";
import Match from "@/components/MatchCard";
import { finalRounds } from "@/utils/constants";

export default {
  name: "MatchList",
  components: {
    Match,
  },
  data() {
    return {
      Default,
      selectedCompetition: {},
      selectedRound: undefined,
      competitions: [],
      matches: [],
    };
  },
  computed: {
    rounds() {
      if (this.selectedCompetition) {
        const regularRoundsMapped = range(
          1,
          this.selectedCompetition.regularSeasonRounds + 1,
        ).map((number) => ({
          label: `Round ${number}`,
          type: "Regular",
          number,
        }));
        const finalRoundsMapped =
          finalRounds[this.selectedCompetition.finalSeasonRounds];
        let allRounds = [{ label: "Any Round" }];
        if (regularRoundsMapped && regularRoundsMapped.length > 0)
          allRounds = allRounds.concat(regularRoundsMapped);
        if (finalRoundsMapped && finalRoundsMapped.length > 0)
          allRounds = allRounds.concat(finalRoundsMapped);

        return allRounds;
      }
      return [];
    },
    filteredMatches() {
      if (this.selectedRound && this.selectedRound.number) {
        return this.matches.filter(
          (match) =>
            match.round.number === this.selectedRound.number &&
            match.round.type === this.selectedRound.type,
        );
      } else {
        return this.matches;
      }
    },
  },
  mounted() {
    this.$http
      .get("/nrl/api/v1/elite/competitions")
      .then((response) => {
        if (response) {
          this.competitions = response.data.data;
          if (this.competitions.length > 0) {
            this.selectedCompetition = this.competitions[0];
          }
        }
      })
      .catch((e) => {
        this.$notify.error({
          title: "Error",
          message:
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : "There has been an error",
        });
        this.$router.push("/landing");
      });
  },
  methods: {
    select(match) {
      if (match.status !== "final") {
        this.$router.push({ name: "matchevents", params: { id: match._id } });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Match has been marked as Final",
        });
      }
    },
    matchTime(dateTime) {
      return moment(dateTime).format("h:mm a");
    },
    matchDate(dateTime) {
      return moment(dateTime).format("dddd Do MMMM");
    },
    randomStyle() {
      return {
        backgroundColor: randomChoice(["#fff44e", "#f0ad4e", "#5cc0de"]),
      };
    },
  },
  watch: {
    selectedCompetition: function(val) {
      this.$http
        .get(`/nrl/api/v1/elite/competitions/${val._id}/matches`)
        .then((response) => {
          if (response) {
            this.matches = response.data.data.filter(
              (obj) => obj.meta && !obj.meta.isBye && !obj.meta.isTba,
            );

            const upcomingMatch = this.matches.find((match) =>
              moment().isSameOrBefore(match.dateTime, "day"),
            );
            if (upcomingMatch && this.rounds.length > 0) {
              this.selectedRound = this.rounds.find(
                (r) =>
                  r.number === upcomingMatch.round.number &&
                  r.type === upcomingMatch.round.type,
              );
            }
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: "Error",
            message:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : "There has been an error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.w-45 {
  width: 45%;
}

.filters {
  width: 100%;
  justify-content: space-between;
}

::v-deep input {
  font-size: 1rem;
  color: $black;

  &::placeholder {
    font-size: 1rem;
  }
}
</style>
