export const startingPositions = [
  { type: "fullback", code: 12, number: 1 },
  { type: "winger", code: 11, number: 2 },
  { type: "centre", code: 9, number: 3 },
  { type: "centre", code: 10, number: 4 },
  { type: "winger", code: 13, number: 5 },
  { type: "five - eighth", code: 7, number: 6 },
  { type: "halfback", code: 8, number: 7 },
  { type: "prop", code: 1, number: 8 },
  { type: "hooker", code: 2, number: 9 },
  { type: "prop", code: 3, number: 10 },
  { type: "second row", code: 4, number: 11 },
  { type: "second row", code: 5, number: 12 },
  { type: "lock", code: 6, number: 13 },
];

export const positionCodes = [
  "FB",
  "RW",
  "RC",
  "LC",
  "LW",
  "F/E",
  "HB",
  "LPR",
  "HK",
  "RPR",
  "LSR",
  "RSR",
  "LR",
];
//
// export const position2Code = {
//   'fullback': 'FB',
//   'winger':''
//   'centre':
//   'winger':
//   'five - eighth':
//   'halfback':
//   'prop':
//   'hooker':
//   'prop':
//   'second row':
//   'lock':
//   'interchange':
//   'reserve':
// }

export const nonPlayerRoles = [
  {
    name: "Coach",
    type: "coach",
    memberType: "coach",
  },
  {
    name: "Assistant Coach",
    type: "assistant-coach",
    memberType: "coach",
  },
  {
    name: "Manager",
    type: "manager",
    memberType: "volunteer",
  },
  {
    name: "Blue Shirt",
    type: "trainer-2",
    memberType: "trainer",
  },
  {
    name: "Yellow Shirt",
    type: "trainer-3",
    memberType: "trainer",
  },
  {
    name: "Orange Shirt",
    type: "trainer-4",
    memberType: "trainer",
  },
  {
    name: "Chief (CMO)",
    type: "cmo",
    memberType: "trainer",
  },
  {
    name: "Assistant (AMO)",
    type: "amo",
    memberType: "trainer",
  },
];

export const interchanges = [
  { name: "Regular Interchange - counts", type: 10 },
  { name: "HIA Free Interchange", type: 11 },
  { name: "HIA Regular Play - free", type: 12 },
  { name: "HIA Return To Field", type: 13 },
  { name: "Sin Bin", type: 14 },
  { name: "Sin Bin Return To Field", type: 15 },
  { name: "Send Off", type: 16 },
  { name: "Player Off-field", type: 200 },
  { name: "Player On-field", type: 201 },
  { name: "Foul Play - Free Interchange", type: 202 },
  { name: "Foul Play - HIA Regular Play Free Interchange", type: 203 },
  {
    name: "HIA - Cleared To Return To Field within 15 minutes - free",
    type: 204,
  },
  // Jira GRBAU-596 Change workflow to use off field player workflow
  // TODO Completely remove type from codebase
  // {
  //   name: 'HIA - Cleared To Return To Field outside 15 minutes - counts',
  //   type: 205,
  // },
  { name: "Foul Play Return - Free Interchange", type: 206 },
  {
    name: "HIA - Not Cleared To Return To Play - counts",
    type: 207,
  },
  {
    name: "HIA - Cleared however remaining on bench - counts",
    type: 208,
  },
  {
    name: "HIA - Not Cleared To Return To Play - free",
    type: 209,
  },
  {
    name: "HIA - Cleared however remaining on bench - free",
    type: 210,
  },
  {
    name: "Foul Play - Not Cleared To Return To Play - free",
    type: 211,
  },
  {
    name: "Activate 18th Player - Player to Interchange",
    type: 999,
  },
  {
    name: "Activate 18th Player - Player to Field - free",
    type: 1000,
  },
];

export const eliteGameStates = [
  { name: "Pre Game", type: 2 },
  { name: "First Half", type: 3 },
  { name: "Half Time", type: 4 },
  { name: "Second Half", type: 5 },
  { name: "Extra Time", type: 6 },
  { name: "Final", type: 8 },
];

export const gamePeriods = [
  { name: "First Half", type: 1 },
  { name: "Second Half", type: 2 },
  { name: "Extra Time", type: 3 },
];
export const gamePeriodsTypes = gamePeriods.map((s) => s.type);

export const teamListStatus = [
  { name: "Mid Week", type: "mid-week" },
  { name: "24 Hour", type: "24h" },
  { name: "1 Hour", type: "1h" },
  { name: "Final", type: "final" },
];

export const teamListStatusCoach = [
  { name: "Mid Week", type: "mid-week" },
  { name: "24 Hour", type: "24h" },
  { name: "1 Hour", type: "1h" },
];

export const finalRounds = {
  1: [{ type: "Final", number: 1, label: "Grand Final" }],
  2: [
    { type: "Final", number: 1, label: "Semi Finals" },
    { type: "Final", number: 2, label: "Grand Final" },
  ],
  3: [
    { type: "Final", number: 1, label: "Semi Finals" },
    { type: "Final", number: 2, label: "Preliminary Final" },
    { type: "Final", number: 3, label: "Grand Final" },
  ],
  4: [
    { type: "Final", number: 1, label: "Elimination Finals" },
    { type: "Final", number: 2, label: "Semi Finals" },
    { type: "Final", number: 3, label: "Preliminary Final" },
    { type: "Final", number: 4, label: "Grand Final" },
  ],
};

export const orgtree = {
  1: {
    name: "Super Admin",
    dependencies: {
      1: [],
    },
    type: 1,
  },
  2: {
    name: "State Admin",
    dependencies: {
      1: ["states"],
      2: ["states"],
    },
    type: 2,
  },
  3: {
    name: "Region Admin",
    dependencies: {
      1: ["states", "regions"],
      2: ["regions"],
      3: ["regions"],
    },
    type: 3,
  },
  4: {
    name: "Association Admin",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 4,
  },
  5: {
    name: "Club Admin",
    dependencies: {
      1: ["states", "regions", "associations", "clubs"],
      2: ["regions", "associations", "clubs"],
      3: ["associations", "clubs"],
      4: ["clubs"],
      5: ["clubs"],
    },
    type: 5,
  },
  6: {
    name: "Team Admin",
    dependencies: {
      1: ["states", "regions", "associations", "clubs", "teams"],
      2: ["regions", "associations", "clubs", "teams"],
      3: ["associations", "clubs", "teams"],
      4: ["clubs", "teams"],
      5: ["teams"],
      6: ["teams"],
    },
    type: 6,
  },
  50: {
    name: "Gameday Admin",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 50,
  },
  51: {
    name: "Elite Gameday Admin",
    dependencies: {
      1: ["states", "regions", "associations"],
      2: ["regions", "associations"],
      3: ["associations"],
      4: ["associations"],
    },
    type: 51,
  },
  98: {
    name: "Game Dev Region Admin",
    dependencies: {
      1: ["game-dev-regions"],
      98: ["game-dev-regions"],
    },
    type: 98,
  },
  99: {
    name: "Program Admin",
    dependencies: {
      1: ["game-dev-regions"],
      98: ["game-dev-regions"],
    },
    type: 99,
  },
  101: {
    name: "Elite Team Admin",
    dependencies: {
      1: ["states", "regions", "associations", "clubs", "teams"],
      2: ["regions", "associations", "clubs", "teams"],
      3: ["associations", "clubs", "teams"],
      4: ["clubs", "teams"],
      5: ["teams"],
      6: ["teams"],
    },
    type: 101,
  },
  418: {
    name: "Developer",
    dependencies: {},
    type: 418,
  },
};
